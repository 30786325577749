import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
	CategoriasSVG,
	DashboardSVG,
	IntegracaoSapSVG,
	MotivosBonificacaoSVG,
	MotivosReclamacaoSVG,
	MotivosReprovacaoSVG,
	PedidosSVG,
	TractorSvg,
	PremiacoesSVG,
	BonificacoesSVG,
	NotificacaoMenuSVG,
	TreinamentosSVG,
	RelatorioPDFSVG,
	ExportacaoSVG,
	TipoPremiacaoSVG,
	CarrinhoMaisMassaSVG,
	GruposDeUsuarioSVG,
	ConsultorSVG,
	StatusSVG,
	NegociacaoSVG,
	PagarPremiacaoSVG,
	AntecipacaoSVG,
	GrupoProdutoOpcaoSVG,
	BagOfMoneySVG,
	ParcelaSVG,
	DocumentWarningSVG,
} from "../assets/svg/icon";
import Permissoes from "enum/permissao-enum";

const dashBoardNavTree = [
	{
		key: "dashboard",
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: "dashboard",
		icon: DashboardSVG,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "dashboard-administrativo",
		path: `${APP_PREFIX_PATH}/dashboard-administrativo`,
		title: "dashboard-administrativo",
		icon: DashboardSVG,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.ADMINISTRATIVO.DASHBOARD_ADMINISTRATIVO,
	},
	{
		key: "lista-pedidos",
		path: `${APP_PREFIX_PATH}/lista-pedidos`,
		title: "lista-pedidos",
		icon: PedidosSVG,
		breadcrumb: false,
		permissao: [
			Permissoes.PEDIDOS.VISUALIZAR,
			Permissoes.PEDIDOS.VISUALIZAR_TODOS,
			Permissoes.PEDIDOS.VISUALIZAR_REGIONAL,
			Permissoes.PEDIDOS.VISUALIZAR_PEDIDOS_PRODUTOS_PROPRIO,
		],
		submenu: [],
	},
	{
		key: "lista-solicitacoes-cancelamento-pedido",
		path: `${APP_PREFIX_PATH}/lista-solicitacoes-cancelamento-pedido`,
		title: "lista-solicitacoes-cancelamento-pedido",
		icon: PedidosSVG,
		breadcrumb: false,
		permissao: [
			Permissoes.PEDIDOS.VISUALIZAR_E_APROVAR_SOLICITACOES_CANCELAMENTO,
		],
		submenu: [],
	},
	{
		key: "lista-pedidos-sem-variacao",
		path: `${APP_PREFIX_PATH}/lista-pedidos-sem-variacao`,
		title: "lista-pedidos-sem-variacao",
		icon: DocumentWarningSVG,
		breadcrumb: false,
		indexNotificacoes: "pedidosSemVariacao",
		permissao: [Permissoes.PEDIDOS_SEM_VARIACAO.VISUALIZAR],
		submenu: [],
	},
	{
		showTitle: false,
		submenu: [
			{
				key: "financeiro",
				title: "financeiro",
				icon: BagOfMoneySVG,
				breadcrumb: false,
				submenu: [
					{
						key: "lista-premiacoes",
						path: `${APP_PREFIX_PATH}/lista-premiacoes`,
						title: "lista-premiacoes",
						icon: PremiacoesSVG,
						breadcrumb: false,
						permissao: [
							Permissoes.COMISSOES.VISUALIZAR,
							Permissoes.COMISSOES.VISUALIZAR_TODOS,
							Permissoes.COMISSOES.VISUALIZAR_REGIONAL,
						],
						submenu: [],
					},
					{
						key: "pagar-premiacoes",
						path: `${APP_PREFIX_PATH}/lista-pagar-premiacoes`,
						title: "pagar-premiacoes",
						icon: PagarPremiacaoSVG,
						breadcrumb: false,
						permissao: [Permissoes.COMISSOES.CONFIRMAR_PAGAMENTO],
						submenu: [],
					},
					{
						key: "requisicoes-antecipacao",
						path: `${APP_PREFIX_PATH}/lista-requisicoes-antecipacao`,
						title: "lista-requisicoes-antecipacao",
						icon: AntecipacaoSVG,
						breadcrumb: false,
						permissao: [
							Permissoes.COMISSOES.ACEITAR_E_RECUSAR_ANTECIPACAO,
						],
						submenu: [],
					},
					{
						key: "lista-parcelas",
						path: `${APP_PREFIX_PATH}/lista-parcelas`,
						title: "lista-parcelas",
						icon: ParcelaSVG,
						breadcrumb: false,
						permissao: [Permissoes.PARCELA.VISUALIZAR],
						submenu: [],
					},
				],
			},
		],
	},
	{
		key: "lista-produtos",
		path: `${APP_PREFIX_PATH}/lista-produtos`,
		title: "lista-produtos",
		icon: CarrinhoMaisMassaSVG,
		breadcrumb: false,
		submenu: [],
		permissao: [
			Permissoes.PRODUTOS.VISUALIZAR,
			Permissoes.PRODUTOS.VISUALIZAR_PROPRIOS_PRODUTOS,
		],
	},
	{
		showTitle: false,
		permissao: [Permissoes.CONSULTOR.VISUALIZAR],
		submenu: [
			{
				key: "consultores",
				title: "consultores",
				icon: ConsultorSVG,
				breadcrumb: false,
				permissao: [Permissoes.CONSULTOR.VISUALIZAR],
				submenu: [
					{
						key: "lista-consultores",
						path: `${APP_PREFIX_PATH}/lista-consultores`,
						title: "lista-consultores",
						breadcrumb: false,
						submenu: [],
					},
					{
						key: "mapa-consultores",
						path: `${APP_PREFIX_PATH}/mapa-consultores`,
						title: "mapa-consultores",
						breadcrumb: false,
						submenu: [],
					},
					{
						key: "gerenciar-produtores",
						path: `${APP_PREFIX_PATH}/gerenciar-produtores`,
						title: "gerenciar-produtores",
						breadcrumb: false,
						submenu: [],
					},
					{
						key: "manual-consultor",
						path: `${APP_PREFIX_PATH}/manual-consultor`,
						title: "manual-consultor",
						breadcrumb: false,
						submenu: [],
					},
				],
			},
		],
	},

	{
		key: "lista-produtor",
		path: `${APP_PREFIX_PATH}/lista-produtor`,
		title: "lista-produtor",
		icon: TractorSvg,
		breadcrumb: false,
		permissao: [
			Permissoes.PRODUTORES.VISUALIZAR_TODOS,
			Permissoes.PRODUTORES.VISUALIZAR_PROPRIOS,
		],
		submenu: [],
	},
	{
		key: "lista-bonificacoes",
		path: `${APP_PREFIX_PATH}/lista-bonificacoes`,
		title: "lista-bonificacoes",
		icon: BonificacoesSVG,
		breadcrumb: false,
		permissao: [
			Permissoes.BONIFICACOES.VISUALIZAR,
			Permissoes.BONIFICACOES.VISUALIZAR_TODOS,
			Permissoes.BONIFICACOES.VISUALIZAR_REGIONAL,
		],
		submenu: [],
	},
	{
		key: "lista-negociacoes",
		path: `${APP_PREFIX_PATH}/lista-negociacoes`,
		title: "lista-negociacoes",
		icon: NegociacaoSVG,
		breadcrumb: false,
		permissao: [Permissoes.NEGOCIACAO.VISUALIZAR_LISTA],
		submenu: [],
	},
	// {
	// 	key: "lista-cotacoes",
	// 	path: `${APP_PREFIX_PATH}/lista-cotacoes`,
	// 	title: "lista-cotacoes",
	// 	icon: NegociacaoSVG,
	// 	breadcrumb: false,
	// 	permissao: [Permissoes.NEGOCIACAO.VISUALIZAR_LISTA],
	// 	submenu: [],
	// },
	{
		key: "relatorio",
		path: `${APP_PREFIX_PATH}/relatorio`,
		title: "relatorios",
		icon: RelatorioPDFSVG,
		breadcrumb: false,
		// permissao: Permissoes.MOTIVOS_RECLAMACAO.VISUALIZAR,
		submenu: [],
	},
	{
		key: "notificacao",
		path: `${APP_PREFIX_PATH}/notificacao`,
		title: "notificacao",
		icon: NotificacaoMenuSVG,
		breadcrumb: false,
		permissao: Permissoes.NOTIFICACAO.VISUALIZAR,
		submenu: [],
	},
	{
		key: "treinamentos",
		path: `${APP_PREFIX_PATH}/treinamentos`,
		title: "treinamentos",
		icon: TreinamentosSVG,
		breadcrumb: false,
		permissao: Permissoes.TREINAMENTOS.VISUALIZAR,
		submenu: [],
	},
	{
		key: "exportacao",
		path: `${APP_PREFIX_PATH}/exportacao`,
		title: "exportacao",
		icon: ExportacaoSVG,
		breadcrumb: false,
		permissao: [
			Permissoes.EXPORTACAO.EXPORTAR_TODOS,
			Permissoes.EXPORTACAO.EXPORTAR_REGIONAL,
		],
		submenu: [],
	},
	{
		showTitle: true,
		title: "secundario",
		submenu: [
			{
				key: "cadastro-secundario",
				title: "cadastro-secundario",
				breadcrumb: false,
				submenu: [
					{
						key: "lista-grupo-produto-opcao",
						path: `${APP_PREFIX_PATH}/lista-grupo-produto-opcao`,
						title: "lista-grupo-produto-opcao",
						icon: GrupoProdutoOpcaoSVG,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.PRODUTOS.GRUPO_PRODUTO_OPCAO,
					},
					{
						key: "lista-status-pedido",
						path: `${APP_PREFIX_PATH}/lista-status-pedido`,
						title: "lista-status-pedido",
						permissao: Permissoes.STATUS_PEDIDO.VISUALIZAR,
						icon: StatusSVG,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: "lista-grupos-usuario",
						path: `${APP_PREFIX_PATH}/lista-grupos-usuario`,
						title: "lista-grupos-usuario",
						icon: GruposDeUsuarioSVG,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.GRUPOS_DE_USUARIO.VISUALIZAR,
					},
					{
						key: "lista-motivos-bonificacao",
						path: `${APP_PREFIX_PATH}/lista-motivos-bonificacao`,
						title: "lista-motivos-bonificacao",
						icon: MotivosBonificacaoSVG,
						breadcrumb: false,
						permissao: Permissoes.MOTIVOS_BONIFICACAO.VISUALIZAR,
						submenu: [],
					},
					{
						key: "lista-motivos-reprovacao",
						path: `${APP_PREFIX_PATH}/lista-motivos-reprovacao`,
						title: "lista-motivos-reprovacao",
						icon: MotivosReprovacaoSVG,
						breadcrumb: false,
						permissao: Permissoes.MOTIVOS_REPROVACAO.VISUALIZAR,
						submenu: [],
					},
					{
						key: "lista-motivos-reclamacao",
						path: `${APP_PREFIX_PATH}/lista-motivos-reclamacao`,
						title: "lista-motivos-reclamacao",
						icon: MotivosReclamacaoSVG,
						breadcrumb: false,
						permissao: Permissoes.MOTIVOS_RECLAMACAO.VISUALIZAR,
						submenu: [],
					},
					{
						key: "lista-modelos-mensagem-agendada",
						path: `${APP_PREFIX_PATH}/lista-modelos-mensagem-agendada`,
						title: "lista-modelos-mensagem-agendada",
						icon: NotificacaoMenuSVG,
						breadcrumb: false,
						permissao:
							Permissoes.MODELO_MENSAGEM_AGENDADA.VISUALIZAR,
						submenu: [],
					},
					{
						key: "lista-tipos-produto",
						path: `${APP_PREFIX_PATH}/lista-tipos-produto`,
						title: "lista-tipos-produto",
						icon: CategoriasSVG,
						breadcrumb: false,
						permissao: Permissoes.CATEGORIAS.VISUALIZAR,
						submenu: [],
					},
					{
						key: "lista-categorias",
						path: `${APP_PREFIX_PATH}/lista-categorias`,
						title: "lista-categorias",
						icon: CategoriasSVG,
						breadcrumb: false,
						permissao: Permissoes.CATEGORIAS.VISUALIZAR,
						submenu: [],
					},
					{
						key: "lista-tipos-premiacao",
						path: `${APP_PREFIX_PATH}/lista-tipos-premiacao`,
						title: "lista-tipos-premiacao",
						icon: TipoPremiacaoSVG,
						breadcrumb: false,
						permissao: Permissoes.TIPO_PREMIACAO.VISUALIZAR,
						submenu: [],
					},
				],
			},
		],
	},
	{
		showTitle: true,
		title: "integracao",
		permissao: Permissoes.INTEGRACAO_SAP.VISUALIZAR,
		submenu: [
			{
				key: "integracao-sap",
				title: "integracao-sap",
				icon: IntegracaoSapSVG,
				breadcrumb: false,
				indexNotificacoes: "somaErrosSap",
				submenu: [
					{
						key: "integracao-sap/lista-enderecos-validar",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-enderecos-validar`,
						title: "integracao-sap/lista-enderecos-validar",
						breadcrumb: false,
						indexNotificacoes: "enderecoAValidar",
						submenu: [],
					},
					{
						key: "integracao-sap/lista-produtos-erro",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-produtos-erro`,
						title: "integracao-sap/lista-produtos-erro",
						breadcrumb: false,
						indexNotificacoes: "produtosComErro",
						submenu: [],
					},
					{
						key: "integracao-sap/lista-consultores-erro",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-consultores-erro`,
						title: "integracao-sap/lista-consultores-erro",
						breadcrumb: false,
						indexNotificacoes: "consultoresComErro",
						submenu: [],
					},
					{
						key: "integracao-sap/lista-produtores-erro",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-produtores-erro`,
						title: "integracao-sap/lista-produtores-erro",
						breadcrumb: false,
						indexNotificacoes: "produtoresComErro",
						submenu: [],
					},
					{
						key: "integracao-sap/lista-pedidos-erro-lead",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-pedidos-erro-lead`,
						title: "integracao-sap/lista-pedidos-erro-lead",
						breadcrumb: false,
						submenu: [],
						indexNotificacoes: "pedidosComErroLead",
					},
					{
						key: "integracao-sap/lista-pedidos-erro",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-pedidos-erro`,
						title: "integracao-sap/lista-pedidos-erro",
						breadcrumb: false,
						indexNotificacoes: "pedidosComErro",
						submenu: [],
					},
					// {
					//   key: 'integracao-sap/lista-comissoes-erro',
					//   path: `${APP_PREFIX_PATH}/integracao-sap/lista-comissoes-erro`,
					//   title: 'integracao-sap/lista-comissoes-erro',
					//   breadcrumb: false,
					//   indexNotificacoes: 'comissoesComErro',
					//   submenu: []
					// },
					{
						key: "integracao-sap/lista-bonificacoes-erro",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-bonificacoes-erro`,
						title: "integracao-sap/lista-bonificacoes-erro",
						breadcrumb: false,
						indexNotificacoes: "bonificacoesComErro",
						submenu: [],
					},
				],
			},
		],
	},
	{
		showTitle: true,
		title: "regras-integracao-sap",
		permissao: Permissoes.REGRAS_SAP.VISUALIZAR,
		submenu: [
			{
				key: "regras-integracao-sap",
				title: "regras-integracao-sap",
				icon: IntegracaoSapSVG,
				breadcrumb: false,
				submenu: [
					{
						key: "regras-sap-filial",
						path: `${APP_PREFIX_PATH}/regras-sap-filial`,
						title: "regras-sap-filial",
						breadcrumb: false,
						submenu: [],
					},
					{
						key: "regras-sap-utilizacao",
						path: `${APP_PREFIX_PATH}/regras-sap-utilizacao`,
						title: "regras-sap-utilizacao",
						breadcrumb: false,
						submenu: [],
					},
					{
						key: "regras-sap-operacao-interna",
						path: `${APP_PREFIX_PATH}/regras-sap-operacao-interna`,
						title: "regras-sap-operacao-interna",
						breadcrumb: false,
						submenu: [],
					},
					// {
					// 	key: "regras-sap-centro-custo",
					// 	path: `${APP_PREFIX_PATH}/regras-sap-centro-custo`,
					// 	title: "regras-sap-centro-custo",
					// 	breadcrumb: false,
					// 	submenu: [],
					// },
				],
			},
		],
	},
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
