import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import JwtAuthService from "../../services/JwtAuthService";
import MenuService from "../../services/MenuService";

export const AppViews = () => {
	const populateMenu = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		} catch (e) {}
		setInterval(() => {
			MenuService.populateMenuData();
		}, 30000);
	};

	useEffect(() => {
		populateMenu();
	}, []);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/dashboard`}
					component={lazy(() => import(`./dashboard`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/dashboard-administrativo`}
					component={lazy(() => import(`./dashboard-administrativo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-grupos-usuario`}
					component={lazy(() => import(`./lista-grupos-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario/:id`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>

				{/*  */}

				<Route
					path={`${APP_PREFIX_PATH}/lista-grupo-produto-opcao`}
					component={lazy(() =>
						import(`./lista-grupo-produto-opcao`),
					)}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/grupo-produto-opcao/:id`}
					component={lazy(() => import(`./grupo-produto-opcao`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/grupo-produto-opcao`}
					component={lazy(() => import(`./grupo-produto-opcao`))}
				/>

				{/* Integração SAP */}

				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-produtos-erro`}
					component={lazy(() =>
						import(`./integracao-sap/lista-produtos-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/produto-erro/:id`}
					component={lazy(() =>
						import(`./integracao-sap/produto-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-consultores-erro`}
					component={lazy(() =>
						import(`./integracao-sap/lista-consultores-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/consultor-erro/:id`}
					component={lazy(() =>
						import(`./integracao-sap/consultor-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-produtores-erro`}
					component={lazy(() =>
						import(`./integracao-sap/lista-produtores-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/produtor-erro/:id`}
					component={lazy(() =>
						import(`./integracao-sap/produtor-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-pedidos-erro`}
					component={lazy(() =>
						import(`./integracao-sap/lista-pedidos-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-pedidos-erro-lead`}
					component={lazy(() =>
						import(`./integracao-sap/lista-pedidos-erro-lead`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/pedido-erro/:id`}
					component={lazy(() =>
						import(`./integracao-sap/pedido-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-comissoes-erro`}
					component={lazy(() =>
						import(`./integracao-sap/lista-comissoes-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/comissao-erro/:id`}
					component={lazy(() =>
						import(`./integracao-sap/comissao-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-bonificacoes-erro`}
					component={lazy(() =>
						import(`./integracao-sap/lista-bonificacoes-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/bonificacao-erro/:id`}
					component={lazy(() =>
						import(`./integracao-sap/bonificacao-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-enderecos-validar`}
					component={lazy(() =>
						import(`./integracao-sap/lista-enderecos-validar`),
					)}
				/>

				{/* Regras Integração SAP */}

				<Route
					path={`${APP_PREFIX_PATH}/regras-sap-filial`}
					component={lazy(() => import(`./regras-sap-filial`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/regras-sap-utilizacao`}
					component={lazy(() => import(`./regras-sap-utilizacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/regras-sap-operacao-interna`}
					component={lazy(() =>
						import(`./regras-sap-operacao-interna`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/regras-sap-centro-custo`}
					component={lazy(() => import(`./regras-sap-centro-custo`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/pedido/tela-sucesso`}
					component={lazy(() => import(`./pedido/tela-sucesso`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/notificacao`}
					component={lazy(() => import(`./notificacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/exportacao`}
					component={lazy(() => import(`./exportacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-tipos-premiacao`}
					component={lazy(() => import(`./lista-tipos-premiacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tipo-premiacao/:id`}
					component={lazy(() => import(`./tipo-premiacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tipo-premiacao`}
					component={lazy(() => import(`./tipo-premiacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-carteira-de-clientes`}
					component={lazy(() =>
						import(`./lista-carteira-de-clientes`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-carteira-de-clientes/:id`}
					component={lazy(() =>
						import(`./detalhes-carteira-de-clientes`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-pedido/:id`}
					component={lazy(() => import(`./detalhes-pedido`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-produto/:id`}
					component={lazy(() => import(`./detalhes-produto`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-portfolio`}
					component={lazy(() => import(`./lista-portfolio`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-pedidos`}
					component={lazy(() => import(`./lista-pedidos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/pedido/:id`}
					component={lazy(() => import(`./pedido`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/pedido`}
					component={lazy(() => import(`./pedido`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/lista-pedidos-sem-variacao`}
					component={lazy(() =>
						import(`./lista-pedidos-sem-variacao`),
					)}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/lista-produtos`}
					component={lazy(() => import(`./lista-produtos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/produto/:id`}
					component={lazy(() => import(`./produto`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/produto`}
					component={lazy(() => import(`./produto`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/lista-produtor`}
					component={lazy(() => import(`./lista-produtor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/produtor/:id`}
					component={lazy(() => import(`./produtor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/produtor`}
					component={lazy(() => import(`./produtor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-produtor/:id`}
					component={lazy(() => import(`./detalhes-produtor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-categorias`}
					component={lazy(() => import(`./lista-categorias`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/categoria/:id`}
					component={lazy(() => import(`./categoria`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/categoria`}
					component={lazy(() => import(`./categoria`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/lista-tipos-produto`}
					component={lazy(() => import(`./lista-tipos-produto`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/tipo-produto/:id`}
					component={lazy(() => import(`./tipo-produto`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/tipo-produto`}
					component={lazy(() => import(`./tipo-produto`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/lista-bonificacoes`}
					component={lazy(() => import(`./lista-bonificacoes`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/detalhes-bonificacao/:id`}
					component={lazy(() => import(`./detalhes-bonificacao`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/lista-motivos-bonificacao`}
					component={lazy(() =>
						import(`./lista-motivos-bonificacao`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/motivo-bonificacao/:id`}
					component={lazy(() => import(`./motivo-bonificacao/`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/motivo-bonificacao/`}
					component={lazy(() => import(`./motivo-bonificacao/`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-motivos-reclamacao`}
					component={lazy(() => import(`./lista-motivos-reclamacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/motivo-reclamacao/:id`}
					component={lazy(() => import(`./motivo-reclamacao/`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/motivo-reclamacao/`}
					component={lazy(() => import(`./motivo-reclamacao/`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-motivos-reprovacao`}
					component={lazy(() => import(`./lista-motivos-reprovacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/motivo-reprovacao/:id`}
					component={lazy(() => import(`./motivo-reprovacao/`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/motivo-reprovacao/`}
					component={lazy(() => import(`./motivo-reprovacao/`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-status-pedido`}
					component={lazy(() => import(`./lista-status-pedido`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/status-pedido/:id`}
					component={lazy(() => import(`./status-pedido/`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/status-pedido/`}
					component={lazy(() => import(`./status-pedido/`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-parcelas`}
					component={lazy(() => import(`./lista-parcelas`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/detalhes-parcela/:id`}
					component={lazy(() => import(`./detalhes-parcela`))}
				/>

				{/*tarefa de pedidos web*/}
				<Route
					path={`${APP_PREFIX_PATH}/pedido/tela-sucesso`}
					component={lazy(() => import(`./pedido/tela-sucesso`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-produto/:id`}
					component={lazy(() => import(`./detalhes-produto`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-portfolio`}
					component={lazy(() => import(`./lista-portfolio`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-pedidos`}
					component={lazy(() => import(`./lista-pedidos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/pedido/:id`}
					component={lazy(() => import(`./pedido`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/pedido`}
					component={lazy(() => import(`./pedido`))}
				/>

				{/*tarefa de pedido de bonificação*/}
				<Route
					path={`${APP_PREFIX_PATH}/bonificacao/portfolio-bonificacao`}
					component={lazy(() =>
						import(`./bonificacao/portfolio-bonificacao`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/bonificacao/detalhes-produto-bonificacao/:id`}
					component={lazy(() =>
						import(`./bonificacao/detalhes-produto-bonificacao/`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/bonificacao/tela-success`}
					component={lazy(() => import(`./bonificacao/tela-success`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/bonificacao/criacao-bonificacao/:id`}
					component={lazy(() =>
						import(`./bonificacao/criacao-bonificacao/`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/bonificacao/criacao-bonificacao/`}
					component={lazy(() =>
						import(`./bonificacao/criacao-bonificacao/`),
					)}
				/>

				{/*tarefa de antecipação de premiações*/}
				<Route
					path={`${APP_PREFIX_PATH}/lista-premiacoes`}
					component={lazy(() => import(`./lista-premiacoes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/antecipacao-premiacao/detalhes-pedido/:id`}
					component={lazy(() =>
						import(`./antecipacao-premiacao/detalhes-pedido`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/antecipacao-premiacao/detalhes-pedido/`}
					component={lazy(() =>
						import(`./antecipacao-premiacao/detalhes-pedido`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/antecipacao-premiacao/pedido-antecipacao/:id`}
					component={lazy(() =>
						import(`./antecipacao-premiacao/pedido-antecipacao`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/antecipacao-premiacao/pedido-antecipacao/`}
					component={lazy(() =>
						import(`./antecipacao-premiacao/pedido-antecipacao`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/antecipacao-premiacao/resumo-solicitacao/:id/:parcela`}
					component={lazy(() =>
						import(`./antecipacao-premiacao/resumo-solicitacao`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/antecipacao-premiacao/resumo-solicitacao/`}
					component={lazy(() =>
						import(`./antecipacao-premiacao/resumo-solicitacao`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/antecipacao-premiacao/sucesso-antecipacao/:id`}
					component={lazy(() =>
						import(`./antecipacao-premiacao/sucesso-antecipacao`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/antecipacao-premiacao/sucesso-antecipacao/`}
					component={lazy(() =>
						import(`./antecipacao-premiacao/sucesso-antecipacao`),
					)}
				/>

				{/*treinamentos*/}
				<Route
					path={`${APP_PREFIX_PATH}/fluxo-treinamentos/treinamentos`}
					component={lazy(() =>
						import(`./fluxo-treinamentos/treinamentos`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/fluxo-treinamentos/modulo-treinamentos/:id`}
					component={lazy(() =>
						import(`./fluxo-treinamentos/modulo-treinamentos`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/fluxo-treinamentos/modulo-treinamentos/`}
					component={lazy(() =>
						import(`./fluxo-treinamentos/modulo-treinamentos`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/fluxo-treinamentos/visualizacao-treinamentos/:id/:id_modulo`}
					component={lazy(() =>
						import(
							`./fluxo-treinamentos/visualizacao-treinamentos`
						),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/fluxo-treinamentos/visualizacao-treinamentos/:id`}
					component={lazy(() =>
						import(
							`./fluxo-treinamentos/visualizacao-treinamentos`
						),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/fluxo-treinamentos/visualizacao-treinamentos/`}
					component={lazy(() =>
						import(
							`./fluxo-treinamentos/visualizacao-treinamentos`
						),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/treinamentos/`}
					component={lazy(() => import(`./treinamentos`))}
				/>

				{/*tarefa de pedido de negociações*/}
				<Route
					path={`${APP_PREFIX_PATH}/lista-negociacoes`}
					component={lazy(() => import(`./lista-negociacoes`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/lista-cotacoes`}
					component={lazy(() => import(`./lista-cotacoes`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/detalhes-negociacao/:id`}
					component={lazy(() => import(`./detalhes-negociacao/`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/negociacoes-contraproposta/:id`}
					component={lazy(() =>
						import(`./negociacoes-contraproposta/`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/negociacao/`}
					component={lazy(() => import(`./negociacao/`))}
				/>

				{/*tarefa de fluxo de consultores*/}
				<Route
					path={`${APP_PREFIX_PATH}/lista-consultores`}
					component={lazy(() => import(`./lista-consultores`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-consultor/:id`}
					component={lazy(() => import(`./detalhes-consultor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/consultor/:id`}
					component={lazy(() => import(`./consultor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/consultor/`}
					component={lazy(() => import(`./consultor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/mapa-consultores/:id`}
					component={lazy(() => import(`./mapa-consultores`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/mapa-consultores`}
					component={lazy(() => import(`./mapa-consultores`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/gerenciar-produtores`}
					component={lazy(() => import(`./gerenciar-produtores`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/manual-consultor`}
					component={lazy(() => import(`./manual-consultor`))}
				/>
				{/*Relatórios*/}
				<Route
					path={`${APP_PREFIX_PATH}/relatorio`}
					component={lazy(() => import("./relatorio"))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/dashboard`}
					component={lazy(() => import(`./dashboard`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-modelos-mensagem-agendada`}
					component={lazy(() =>
						import(`./lista-modelos-mensagem-agendada`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/modelo-mensagem-agendada/:id`}
					component={lazy(() =>
						import(`./modelo-mensagem-agendada/`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/modelo-mensagem-agendada/`}
					component={lazy(() =>
						import(`./modelo-mensagem-agendada/`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-pagar-premiacoes`}
					component={lazy(() => import(`./lista-pagar-premiacoes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/pagar-premiacoes/:id`}
					component={lazy(() => import(`./pagar-premiacoes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-requisicoes-antecipacao`}
					component={lazy(() =>
						import(`./lista-requisicoes-antecipacao`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/requisicao-antecipacao/:id`}
					component={lazy(() => import(`./requisicao-antecipacao`))}
				/>

				{/* Relatórios */}
				<Route
					path={`${APP_PREFIX_PATH}/relatorio`}
					component={lazy(() => import(`./relatorio`))}
				/>

				{/* Solicitações de cancelar pedido */}

				<Route
					path={`${APP_PREFIX_PATH}/lista-solicitacoes-cancelamento-pedido`}
					component={lazy(() =>
						import(`./lista-solicitacoes-cancelamento-pedido`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/solicitacao-cancelamento-pedido/:id`}
					component={lazy(() =>
						import(`./solicitacao-cancelamento-pedido`),
					)}
				/>

				{/* Trasnformar consultor em líder */}
				{/*<Route*/}
				{/*	path={`${APP_PREFIX_PATH}/lista-consultor-lider`}*/}
				{/*	component={lazy(() => import(`./lista-consultor-lider`))}*/}
				{/*/>*/}
				{/*<Route*/}
				{/*	path={`${APP_PREFIX_PATH}/consultor-lider/:id`}*/}
				{/*	component={lazy(() => import(`./consultor-lider`))}*/}
				{/*/>*/}

				{/* Tarefa fornecedores */}
				{/*<Route*/}
				{/*	path={`${APP_PREFIX_PATH}/fornecedor/:id`}*/}
				{/*	component={lazy(() => import(`./fornecedor/`))}*/}
				{/*/>*/}
				{/*<Route*/}
				{/*	path={`${APP_PREFIX_PATH}/fornecedor/`}*/}
				{/*	component={lazy(() => import(`./fornecedor`))}*/}
				{/*/>*/}
				{/*<Route*/}
				{/*	path={`${APP_PREFIX_PATH}/lista-fornecedores`}*/}
				{/*	component={lazy(() => import(`./lista-fornecedores`))}*/}
				{/*/>*/}

				<Redirect
					from={`${APP_PREFIX_PATH}`}
					to={`${APP_PREFIX_PATH}/dashboard`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
