const Permissoes = {};

Permissoes.PEDIDOS = {
	VISUALIZAR: 1,
	CRIAR: 2,
	EXCLUIR: 3,
	VISUALIZAR_TODOS: 28,
	VISUALIZAR_REGIONAL: 29,
	ALTERAR_STATUS_LOGISTICA: 41,
	ALTERAR_STATUS_FINANCEIRO: 42,
	VISUALIZAR_PEDIDOS_PRODUTOS_PROPRIO: 69,
	CANCELAR_PEDIDO_E_PULAR_ASSINATURA: 89,
	VISUALIZAR_LINK_CLICKSIGN: 90,
	VISUALIZAR_E_APROVAR_SOLICITACOES_CANCELAMENTO: 91,
};

Permissoes.PEDIDOS_SEM_VARIACAO = {
	VISUALIZAR: 103,
};

Permissoes.BONIFICACOES = {
	VISUALIZAR: 10,
	CRIAR: 11,
	EXCLUIR: 12,
	VISUALIZAR_TODOS: 30,
	VISUALIZAR_REGIONAL: 31,
};

Permissoes.COMISSOES = {
	VISUALIZAR: 4,
	CRIAR: 5,
	EXCLUIR: 6,
	VISUALIZAR_TODOS: 32,
	VISUALIZAR_REGIONAL: 33,
	SOLICITAR_ANTECIPACAO: 37,
	CONFIRMAR_PAGAMENTO: 48,
	ACEITAR_E_RECUSAR_ANTECIPACAO: 49,
};

Permissoes.CATEGORIAS = {
	VISUALIZAR: 7,
	CRIAR: 8,
	EXCLUIR: 9,
};

Permissoes.MOTIVOS_BONIFICACAO = {
	VISUALIZAR: 13,
	CRIAR: 14,
	EXCLUIR: 15,
};

Permissoes.MOTIVOS_REPROVACAO = {
	VISUALIZAR: 16,
	CRIAR: 17,
	EXCLUIR: 18,
};

Permissoes.MOTIVOS_RECLAMACAO = {
	VISUALIZAR: 19,
	CRIAR: 20,
	EXCLUIR: 21,
};

Permissoes.NOTIFICACAO = {
	VISUALIZAR: 22,
	CRIAR: 23,
	EXCLUIR: 24,
};

Permissoes.INTEGRACAO_SAP = {
	VISUALIZAR: 25,
	CRIAR: 26,
	EXCLUIR: 27,
};

Permissoes.STATUS_GERAIS = {
	VISUALIZAR: 34,
	CRIAR: 35,
	EXCLUIR: 36,
};

Permissoes.GRUPOS_DE_USUARIO = {
	VISUALIZAR: 38,
	CRIAR: 39,
	EXCLUIR: 40,
};

Permissoes.EXPORTACAO = {
	EXPORTAR_TODOS: 43,
	EXPORTAR_REGIONAL: 47,
};

Permissoes.TIPO_PREMIACAO = {
	VISUALIZAR: 41,
	CRIAR: 42,
	EXCLUIR: 43,
};

Permissoes.MODELO_MENSAGEM_AGENDADA = {
	VISUALIZAR: 44,
	CRIAR: 45,
	EXCLUIR: 46,
};

Permissoes.CONSULTOR = {
	VISUALIZAR: 50,
	CRIAR: 51,
	EXCLUIR: 52,
	TRANSFORMAR_LIDER: 53,
	GERENCIAR_PRODUTORES: 58,
};

Permissoes.TREINAMENTOS = {
	VISUALIZAR: 54,
	CRIAR: 55,
	EDITAR: 56,
};

Permissoes.ADMINISTRATIVO = {
	DASHBOARD_ADMINISTRATIVO: 57,
};

Permissoes.PRODUTOS = {
	VISUALIZAR: 59,
	CRIAR: 60,
	EXCLUIR: 61,
	VIZUALIZAR_NO_APLICATIVO: 63,
	CADASTRAR_COMISSAO: 64,
	VISUALIZAR_PROPRIOS_PRODUTOS: 68,
	GRUPO_PRODUTO_OPCAO: 76,
};

Permissoes.FORNECEDOR = {
	VISUALIZAR: 70,
};

Permissoes.STATUS_PEDIDO = {
	VISUALIZAR: 71,
};

Permissoes.NEGOCIACAO = {
	VISUALIZAR_LISTA: 65,
	EDITAR_NEGOCIACAO: 66,
	CRIAR_CONTRAPROPOSTA: 67,
};

Permissoes.PRODUTORES = {
	VISUALIZAR_TODOS: 72,
	VISUALIZAR_PROPRIOS: 73,
	CRIAR: 74,
	EXCLUIR: 75,
};

Permissoes.REGRAS_SAP = {
	VISUALIZAR: 81,
	CRIAR: 82,
	EXCLUIR: 83,
};

Permissoes.PARCELA = {
	VISUALIZAR: 99,
	VISUALIZAR_TODOS: 100,
	VISUALIZAR_REGIONAL: 101,
	EDITAR: 102,
};

module.exports = Permissoes;
