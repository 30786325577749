import { UPDATE_USER } from "../constants/User";

const initUser = {
	name: null,
	role: null,
	isConsultorLider: false,
	isConsultorLiderEmFormacao: false,
	isRegional: false,
	user: null,
};

const user = (state = initUser, action) => {
	switch (action.type) {
		case UPDATE_USER:
			return {
				...state,
				name: action.name,
				role: action.role,
				isConsultorLider: action.isConsultorLider,
				isConsultorLiderEmFormacao: action.isConsultorLiderEmFormacao,
				isRegional: action.isRegional,
				user: action.user,
			};
		default:
			return state;
	}
};

export default user;
